<template>
  <div class="container">
    <Loader :loading="isLoader" v-if="isLoader" />
    <div class="filters" v-else>
      <div class="filters__item">
        <label class="label">CUC Code:<span class="red">*</span></label>
        <div class="select-box">
          <AutoCompleteDropdown
            :options="cucCodes"
            v-model="selectedCucCode"
            @selected-item="setSelectedCucCode"
            v-if="isCucCodeRefresh"
            :apiPath="apiPath"
            placeholder="Enter 3 digits of CUC Code"
            isAutoFetch
            autoFetchType="CucCodes"
          /><Loader :loading="isCucLoader" :size="size" />
        </div>
      </div>
      <div class="filters__item">
        <label class="label">Revision:<span class="red">*</span></label>
        <div class="select-box">
          <AutoCompleteDropdown
            :options="revisions"
            v-model="selectedRevision"
            @selected-item="setSelectedRevision"
            v-if="isRevisionsRefresh"
            :limit="limit"
          /><Loader :loading="isRevisionLoader" :size="size" />
        </div>
      </div>
      <div class="filters__item">
        <label class="label">Description:</label>
        <div class="tooltip">
          <input type="text" disabled v-model="cucDescription" /><Tooltip
            :hoverText="cucDescription"
          />
        </div>
      </div>
      <div class="filters__item" v-if="isType != 'compare'">
        <label class="label">Status:</label>
        <div class="select-box">
          <AutoCompleteDropdown
            :options="status"
            v-model="selectedStatus"
            @selected-item="setSelectedStatus"
          />
        </div>
      </div>
      <div class="filters__item">
        <Button
          class="ml-8"
          :disabled="isArchiveButton || !role.isUser"
          @click="openLanguageModal('archive')"
          v-if="isType != 'compare'"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M0 2C0 .9.9 0 2 0h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm14 12h4V2H2v12h4c0 1.1.9 2 2 2h4a2 2 0 0 0 2-2zM9 8V5h2v3h3l-4 4-4-4h3z"
            />
          </svg>
          Archive
        </Button>
        <Button
          class="ml-8"
          :disabled="isRenewButton || !role.isUser"
          @click="openLanguageModal('renew')"
          v-if="isType != 'compare'"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M15 9h-3v2h3v3h2v-3h3V9h-3V6h-2v3zM0 3h10v2H0V3zm0 8h10v2H0v-2zm0-4h10v2H0V7zm0 8h10v2H0v-2z"
            />
          </svg>
          Renew
        </Button>
        <Button
          class="ml-8"
          @click="openCompareModal()"
          v-if="isType == 'compare'"
          :disabled="isCompareButton"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M6 6V2c0-1.1.9-2 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h4zm2 0h4a2 2 0 0 1 2 2v4h4V2H8v4zM2 8v10h10V8H2z"
            />
          </svg>
          Compare
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Bus } from "../../eventBus";
import authentication from "../../authentication";
import formulationMappingService from "../../services/formulationMappings";
import loggerService from "../../services/logger"
const Language = () => import("../common/Languages");
export default {
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "../common/Loader"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "../common/AutoCompleteDropdown"),
    Button: () => import(/* webpackPrefetch: true */ "../common/Button"),
  },
  props: {
    isType: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    limit: 1,
    isCucLoader: false,
    isRevisionLoader: false,
    size: "5px",
    isCucCodeRefresh: true,
    isRevisionsRefresh: true,
    selectedStatus: "",
    selectedCucCode: "",
    statusId: "",
    selectedRevision: "",
    cucDescription: "",
    cucCodes: [],
    revisions: [],
    isLoader: true,
    statusValue: null,
  }),
  created() {
    setTimeout(() => {
      this.isLoader = false;
    }, 500);
    this.updateCucStatus("");
  },
  computed: {
    ...mapGetters({
      status: "GET_STATUS",
      cucCodePrimary: "GET_CUC_CODE_PRIMARY",
      revisionNumberPrimary: "GET_REVISION_PRIMARY",
      role: "GET_USER_ROLE",
    }),
    apiPath() {
      return `FormulationMapping/GetCUCCodesForFilter/${authentication.getUserEmail()}/${
        this.statusValue === null || this.statusValue == true
          ? ""
          : this.statusValue
      }`;
    },
    isArchiveButton() {
      return this.selectedCucCode != "" && this.selectedRevision != ""
        ? false
        : true;
    },
    isRenewButton() {
      return this.selectedCucCode != "" && this.selectedRevision != ""
        ? false
        : true;
    },
    isCompareButton() {
      return this.selectedCucCode != "" && this.selectedRevision != ""
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions({      
      updateFormulationBrandProductMapping:
        "updateFormulationBrandProductMapping",
    }),
    ...mapMutations({
      updateRevisionSecondary: "SET_REVISION_SECONDARY",
      updateRevisionPrimary: "SET_REVISION_PRIMARY",
      updateCucStatus: "SET_CUC_STATUS",
      updateCucCodeSecondary: "SET_CUC_CODE_SECONDARY",
      updateCucCodePrimary: "SET_CUC_CODE_PRIMARY",
      updateAdditionalInformation: "SET_ADDITIONAL_INFORMATION",
      updateIsLanguageType: "SET_IS_LANGUAGE_TYPE",
    }),
    clearSelected() {
      this.cucDescription = "";
      this.selectedCucCode = "";
      this.selectedRevision = "";
      this.revisions = [];
      this.cucCodes = [];
    },
    setSelectedStatus(status) {
      if (status === true) {
        this.selectedStatus = "";
        this.clearSelected();
        this.revisions = [];
        this.cucCodes = [];
        this.isRevisionRefresh();
        this.isCucCodesRefresh();
        this.getCucCodes(this.selectedStatus);
        this.updateCucStatus("");
        return;
      }
      this.isCucLoader = true;
      this.clearSelected();
      this.selectedStatus = status.Value;
      this.statusId = status.Id;
      this.updateCucStatus(this.statusId);
      this.getCucCodes(status.Id);
      this.isRevisionRefresh();
    },
    setSelectedCucCode(cucCode) {
      if (cucCode === true) {
        this.clearSelected();
        this.revisions = [];
        this.isRevisionRefresh();
        return;
      }
      this.selectedCucCode = cucCode.Value;
      this.cucDescription = cucCode.Info;
      this.getRevisions(cucCode.Value);
    },
    setSelectedRevision(revision) {
      if (revision === true) {
        this.selectedRevision = "";
        return;
      }
      this.selectedRevision = revision.Value;
      this.cucDescription = revision.Info;
      if (this.isType == "compare") {
        this.updateCucCodeSecondary(this.selectedCucCode);
        this.updateRevisionSecondary(revision);
      } else {
        this.updateCucCodePrimary(this.selectedCucCode);
        this.updateRevisionPrimary(revision);
        this.getDetails(revision);
      }
      this.$emit("on-filtered", true);
    },
    openLanguageModal(type) {
      this.updateIsLanguageType(type);
      Bus.$emit("open", {
        component: Language,
        title:
          type === "archive"
            ? `Formulation/CUC will be "Archived" for the selected country and language`
            : `Formulation/CUC will be "Renewed" for the selected country and language`,
        size: "md",
        closeOnClick: false,
      });
    },
    getCucCodes(status) {
      this.statusValue = status;
      this.isCucLoader = false;
      this.isLoader = false;
      this.isCucCodesRefresh();
    },
    async getRevisions(code) {
      try {
        const { data } = await formulationMappingService.getCUCRevisions(code);
        this.revisions = data;
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      } catch (error) {
        loggerService.logError(error);
        this.revisions = [];
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      }
    },
    async getDetails(revision) {
      try {
        const { data } = await formulationMappingService.getCUCAdditionalInfo(revision);

        this.updateAdditionalInformation(data);
      } catch (error) {
        loggerService.logError(error);
        this.updateAdditionalInformation([]);
      }
    },
    openCompareModal() {
      this.$emit("on-compare-button");
    },
    isRevisionRefresh() {
      this.isRevisionsRefresh = false;
      this.$nextTick(() => {
        this.isRevisionsRefresh = true;
      });
    },
    isCucCodesRefresh() {
      this.isCucCodeRefresh = false;
      this.$nextTick(() => {
        this.isCucCodeRefresh = true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.filters__item {
  .tooltip {
    .tooltipTop {
      margin-left: -130px;
      bottom: 120%;
    }
  }
}
</style>
